import React from 'react'
import { LocationMarkerIcon } from '@heroicons/react/solid'

const LocationMarker = ({ lat, lng, onClick }) => {
    return (
        <div className = " hover:cursor-pointer" onClick={onClick}>
            <LocationMarkerIcon className = "absolute -translate-y-1/2 -translate-x-1/2 h-7 w-7 fill-current text-red-500"/>
            
        </div>
    )
}

export default LocationMarker
