import React from "react";
import GoogleMapReact from "google-map-react";
import LocationMarker from "./locationMarker";
import { useStaticQuery, graphql } from "gatsby";
import { useEffect, useState } from "react";
import LocationInfoBox from "./locationInfoBox";
import { CollectionIcon, MapIcon } from "@heroicons/react/solid";

const Map = ({ center, zoom }) => {
  const data = useStaticQuery(graphql`
    query MyQuery {
      allPrismicDealers {
        nodes {
          data {
            address
            dealer_location {
              latitude
              longitude
            }
            dealer_name {
              text
            }
            dealership_image {
              gatsbyImageData
            }
            e_mail_address
            mobile_number
            dealership_navigation {
              url
              target
              slug
            }
            shorthand_address
            slug
          }
        }
      }
    }
  `);

  const [locationInfo, setLocationInfo] = useState(null);

  const markers = data.allPrismicDealers.nodes.map((marker) => (
    <LocationMarker
      lat={marker.data.dealer_location.latitude}
      lng={marker.data.dealer_location.longitude}
      onClick={() =>
        setLocationInfo({
          name: marker.data.dealer_name.text,
          number: marker.data.mobile_number,
          email: marker.data.e_mail_address,
          dealer_name: marker.data.dealer_name,
          dealership_image: marker.data.dealership_image,
          address: marker.data.address,
          shorthand_address: marker.data.shorthand_address,
          slug: marker.data.slug,
          navigation: marker.data.dealership_navigation.url,
        })
      }
    ></LocationMarker>
  ));

  return (
    <>
      <div className="h-full w-full relative">
        <GoogleMapReact
          bootstrapURLKeys={{ key: "AIzaSyDRKL_Gm3KzqV4daaQ6gEIn99PDhKQaUjY" }}
          defaultCenter={center}
          defaultZoom={zoom}
          disableDefaultUI={true}
          options={{
            gestureHandling: "greedy",
            zoomControl: false,
          }}
        >
          {markers}
        </GoogleMapReact>
        {/* <div className=" bg-white absolute w-[90%] z-30 top-4 transform -translate-x-1/2 left-1/2 p-4 rounded-md shadow">
          <h1 className="text-lg text-blue-700 font-medium">
            Locate a Store
          </h1>
          <div className = "flex flex-row">
            <span className = "p-4 text-sm uppercase">Store List</span>
            <span className = "p-4 text-sm uppercase">Become a Dealer</span>
          </div>
        </div> */}

        {locationInfo && <LocationInfoBox info={locationInfo} />}
      </div>
    </>
  );
};

Map.defaultProps = {
  center: {
    lat: 20.5937,
    lng: 78.9629,
  },
  zoom: 6,
};

export default Map;
