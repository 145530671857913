import React from "react";
import Layout from "../components/Layout/layout";
import { useState, useEffect } from "react";
import Map from "../components/Dealers/map";
import { useStaticQuery, Link, graphql } from "gatsby";
import Header from "../components/Layout/header";
import Footer from "../components/Layout/footer";
import SEO from "../components/seo";

const Dealers = () => {

  
  return (
    <>
    <SEO 
    title = "Dealers - Kabira Mobility"/>
        <Header />
      <div className="overflow-hidden h-[calc(100vh-70px)] md:h-[calc(100vh-120px)] w-screen">
        <Map />
      </div>
        <Footer />
    </>
  );
};

export default Dealers;
